@import '@core/scss/core.scss';
@import './assets/scss/styles';

.sup {
    margin-right: 20px;
}

.pd-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}
